








import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  name: 'ClientDetail'
})

export default class extends Vue {
  @Prop({ default: undefined }) private value!: any
  @Prop({ default: 'medium' }) private size!: string
  @Prop({ default: '' }) private classNames!: string

  private model = null

  private created() {
    this.model = this.value === null ? undefined : this.value
  }

  private handleInput(currentValue: any) {
    this.$emit('input', currentValue)
  }
}
