





































































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import Pagination from '@/components/Pagination/index.vue'
import {
  createFilter,
  FilterOperator,
  FilterType, getSortItems,
  prepareListFilters
} from '@/utils/filter'
import { getTranslations, parseTime, numberFormat } from '@/utils'
import { getSearches } from '@/api/searches'

@Component({
  name: 'BridgeEvents',
  components: {
    Pagination
  }
})

export default class extends Vue {
  @Prop({ required: true }) private client!: any
  private total = 0
  private list = []
  private listLoading = false
  private parseTime = parseTime
  private getTranslations = getTranslations
  private numberFormat = numberFormat
  private listQuery = createFilter()

  created() {
    this.getList()
  }

  private prepareFilters() {
    prepareListFilters({
      listQuery: this.listQuery,
      withDefaultSort: true,
      search: '',
      searchFields: [],
      filters: [],
      callback: () => {
        this.listQuery.query?.filters?.items?.push({
          type: FilterType.field,
          key: 'client_id',
          value: this.client.id,
          operator: FilterOperator.eq
        })
      }
    })
  }

  private handleSortChange(params: any = {}) {
    if (this.listQuery?.query?.sort) {
      this.listQuery.query.sort = getSortItems(params)
    }
    this.getList()
  }

  private async getList() {
    this.listLoading = true
    try {
      this.prepareFilters()
      const { data } = await getSearches(this.listQuery)
      this.list = data.collection || []
      this.total = data.pagination.total_items || 0
    } catch (err) {}
    this.listLoading = false
  }
}
