var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-row',{staticClass:"bee-row-container flex-wrap",attrs:{"gutter":8,"type":"flex"}},[_c('div',{staticClass:"container-fluid"},[_c('el-row',{directives:[{name:"loading",rawName:"v-loading.lock",value:(_vm.listLoading),expression:"listLoading",modifiers:{"lock":true}}],staticClass:"bee-main-container",attrs:{"type":"flex"}},[_c('el-col',{attrs:{"span":24}},[_c('el-table',{staticClass:"bee-style-table",attrs:{"data":_vm.list,"border":"","fit":"","highlight-current-row":""},on:{"sort-change":_vm.handleSortChange}},[_c('el-table-column',{attrs:{"align":"center","prop":"dateCreated","label":_vm.$t('table.date'),"sortable":"custom"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(_vm.parseTime(row.date_created)))])]}}])}),_c('el-table-column',{attrs:{"align":"center","prop":"client.translations.first_name,client.translations.last_name","sortable":"custom","label":_vm.$t('table.client')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(_vm.getTranslations(row.client)))])]}}])}),_c('el-table-column',{attrs:{"align":"center","label":_vm.$t('table.availableFor')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(_vm.$t('available_for.' + (row.available_for || 'none'))))])]}}])}),_c('el-table-column',{attrs:{"align":"center","label":_vm.$t('table.category')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(_vm.$t('property_category.' + (row.category || 'none'))))])]}}])}),_c('el-table-column',{attrs:{"align":"center","prop":"meta>acreage_from","sortable":"custom","label":_vm.$t('table.squareMetersFrom')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.acreage_from))])]}}])}),_c('el-table-column',{attrs:{"align":"center","prop":"meta>acreage_to","sortable":"custom","label":_vm.$t('table.squareMetersTo')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.acreage_to))])]}}])}),_c('el-table-column',{attrs:{"align":"center","prop":"meta>price_from","sortable":"custom","label":_vm.$t('table.euroPriceFrom')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(_vm.numberFormat(row.price_from)))])]}}])}),_c('el-table-column',{attrs:{"align":"center","prop":"meta>price_to","sortable":"custom","label":_vm.$t('table.euroPriceTo')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(_vm.numberFormat(row.price_to)))])]}}])}),_c('el-table-column',{attrs:{"align":"center","prop":"meta>construction_year_from","sortable":"custom","label":_vm.$t('table.constructionYearFrom')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.construction_year_from))])]}}])}),_c('el-table-column',{attrs:{"align":"center","prop":"meta>construction_year_to","sortable":"custom","label":_vm.$t('table.constructionYearTo')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.construction_year_to))])]}}])})],1)],1)],1),_c('pagination',{attrs:{"hide-on-single-page":true,"total":_vm.total,"page":_vm.listQuery.query.pagination.page,"limit":_vm.listQuery.query.pagination.page_size},on:{"update:page":function($event){return _vm.$set(_vm.listQuery.query.pagination, "page", $event)},"update:limit":function($event){return _vm.$set(_vm.listQuery.query.pagination, "page_size", $event)},"pagination":_vm.getList}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }